import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';

import { AppSettings } from '../../app.settings';
@Component({
    selector: 'otk-cmp-quantidade-em-estoque',
    templateUrl: './cmp-quantidade-em-estoque.component.html',
    styleUrls: ['./cmp-quantidade-em-estoque.component.scss'],
})
export class CmpQuantidadeEmEstoqueComponent implements OnInit {
    @Input() label: string = 'Quantidade em Estoque *';
    @Input() inputWidthPx = '240';
    @Input() codigoDaFilial: number;
    @Input() codigoDoAlmoxarifado: number;
    @Input() codigoDaSecao: string;
    @Input() idProd: number;
    @Input() codigoDoProjeto: number;
    @Input() numeroDeSerie: string;
    @Input() showHelp: boolean = true;
    @Input() showRefresh: boolean = true;

    @Input() quantidadeInicial: number = 0;
    @Input() filtrarPorNumeroDeSerie: boolean = true;
    @Input() atualizar$: Subject<any> = new Subject<any>();

    @Output() quantidadeEmEstoque = new EventEmitter<number>();

    help: string = null;
    qtde: number;
    private _atualizarSubscription: Subscription;
    private _subscription: Subscription;

    constructor(private _http: HttpClient) {}

    ngOnInit() {
        this._atualizarSubscription = this.atualizar$.subscribe(() =>
            this.atualizarQuantidadeEmEstoque()
        );
    }

    ngOnDestroy() {
        this._atualizarSubscription.unsubscribe();
        this._subscription.unsubscribe();
    }

    ngOnChanges() {
        this.help = null;

        if (this.numeroDeSerie && this.numeroDeSerie != '-') {
            this.filtrarPorNumeroDeSerie = true;
        }

        if (this.showHelp) {
            if (this.filtrarPorNumeroDeSerie) {
                this.help = `Quantidade em Estoque por Número de Série/Lote.`;
            } else {
                this.help = `Quantidade em Estoque sem considerar Número de Série/Lote.`;
            }
        }

        this.atualizarQuantidadeEmEstoque();
    }

    atualizarQuantidadeEmEstoque() {
        this._subscription = this._atualizarQuantidadeEmEstoque().subscribe(
            (r) => {
                this.qtde = !r ? 0 : r;
                this.qtde = this.qtde + this.quantidadeInicial;
                this.quantidadeEmEstoque.emit(this.qtde);
            }
        );
    }

    private _atualizarQuantidadeEmEstoque(): Observable<any> {
        if (!this.idProd) {
            return of(0);
        }

        let obj: any = {};
        // FILIAL
        if (this.codigoDaFilial) {
            obj.codigoDaFilial = this.codigoDaFilial;
        }
        // ALMOX
        if (this.codigoDoAlmoxarifado) {
            obj.codigoDoAlmoxarifado = this.codigoDoAlmoxarifado;
        }
        // SECAO
        if (this.codigoDaSecao) {
            obj.codigoDaSecao = this.codigoDaSecao;
        }
        // PRODUTO
        obj.idProduto = this.idProd;
        // NUMERO SERIE
        if (this.filtrarPorNumeroDeSerie) {
            if (this.numeroDeSerie) {
                obj.numeroDeSerie = this.numeroDeSerie;
            } else {
                obj.numeroDeSerie = '-';
            }
        }
        // PROJETO
        if (this.codigoDoProjeto) {
            obj.codigoDoProjeto = this.codigoDoProjeto;
        }

        return this._http.post(
            `${AppSettings.API_ENDPOINT_ESTOQUE}/estoque/quantidade`,
            obj
        );
    }
}
