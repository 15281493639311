import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { hasRequiredField } from './../../app.functions';

@Component({
    selector: 'otk-cmp-numero-de-aprovacoes-selectbox',
    templateUrl: './cmp-numero-de-aprovacoes-selectbox.component.html',
    styleUrls: ['./cmp-numero-de-aprovacoes-selectbox.component.scss'],
})
export class CmpNumeroDeAprovacoesSelectboxComponent implements OnInit {
    @Input() label: string = 'Número de Aprovações';
    @Input() control: FormControl = new FormControl(null, []);
    @Input() placeholder: string = '';
    @Input() width: string = '100%';
    @Input() showClearButton: boolean = true;
    @Input() showDropDownButton: boolean = true;
    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;

    @Output() onSelectedValue = new EventEmitter<any>();

    dataSource: any = [
        {
            value: 0,
            display: 'Não Requer Aprovação',
        },
        {
            value: 1,
            display: 'Uma Aprovação',
        },
        {
            value: 2,
            display: 'Duas Aprovações',
        },
        {
            value: 3,
            display: 'Três Aprovações',
        },
        {
            value: 4,
            display: 'Quatro Aprovações',
        },
    ];

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (this.control && hasRequiredField(this.control)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            // console.log("CONTROL ERROR ->", this.control.errors, this.control.valid);
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else if (this.control.errors.minlength) {
                return {
                    message: `Deve possuir no mínimo ${this.control.errors.minlength.requiredLength} caracteres.`,
                };
            } else if (this.control.errors.maxlength) {
                return {
                    message: `Deve possuir no máximo ${this.control.errors.maxlength.requiredLength} caracteres.`,
                };
            } else {
                return { message: `Inválido.` };
            }
        }
    }

    actionSelectionChanged(e: any) {
        if (!e || !e.selectedItem) {
            this.onSelectedValue.emit(null);
            return;
        }

        let obj = {
            tipoDeRegra: e.selectedItem.value,
            descricaoTipoDeRegra: e.selectedItem.display,
        };
        // console.log("NUMERO APROV REGRA -> ", obj);
        this.onSelectedValue.emit(obj);
    }
}
