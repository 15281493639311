import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators/catchError';
import { map } from 'rxjs/operators/map';

import { AppSettings } from '../../../app.settings';

@Injectable()
export class ColaboradorHelperService {
    constructor(private _http: HttpClient) {}

    existeEmailCadastrado(
        email: string,
        codigoDaEmpresa: number,
        codigoDoUsuario: string
    ): Observable<any> {
        let params = new HttpParams();
        params = params.set('email', email);
        if (codigoDaEmpresa) {
            params = params.set('codigoDaEmpresa', codigoDaEmpresa.toString());
        }

        if (codigoDoUsuario) {
            params = params.set('codigoDoUsuario', codigoDoUsuario);
        }

        return this._http
            .get<any>(
                `${AppSettings.API_ENDPOINT_CADASTROS}/sistema/localizar`,
                { params }
            )
            .pipe(
                map((r: any) => r),
                catchError((e: any) => {
                    // let error = new HandleErrorModel(e);
                    // console.log("Request Error ->", error);
                    return of({
                        result: false,
                        error: true,
                        msg: 'Não foi possível validar o e-mail...',
                    });
                })
            );
    }
}
