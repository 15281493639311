import { Component, Input, OnInit } from '@angular/core';

import { AppRoutingService } from '../../../../app-routing.service';

@Component({
    selector: 'otk-notas-recebidas-tabs-title',
    templateUrl: './notas-recebidas-tabs-title.component.html',
    styleUrls: ['./notas-recebidas-tabs-title.component.scss'],
})
export class NotasRecebidasTabsTitleComponent implements OnInit {
    @Input() status: string;

    visible: boolean = true;

    constructor(private _routingService: AppRoutingService) {}

    ngOnInit() {}

    actionOnChange(e: any) {
        if (!e || !e.value) {
            return;
        }

        if (e.value == 'sincronizadas') {
            this._routingService.notasRecebidasSincronizadas();
        }

        if (e.value == 'comprasConfirmadas') {
            this._routingService.notasRecebidasCompras();
        }
    }
}
