import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DxSelectBoxModule } from 'devextreme-angular';

import { CmpNumeroDeAprovacoesSelectboxComponent } from './cmp-numero-de-aprovacoes-selectbox.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxSelectBoxModule
    ],
    declarations: [
        CmpNumeroDeAprovacoesSelectboxComponent
    ],
    exports: [
        CmpNumeroDeAprovacoesSelectboxComponent,
    ]
})
export class NumeroDeAprovacoesSharedComponentsModule { }
